.container-modulos{
    padding: 20 20 20 20px;
    background: white;
    min-height: 91vh;
}

.scroll-tabla{
    overflow-x: auto;
    height: 40dvh;
}

.card-option{
    cursor: pointer;
    transition: background-color 0.3s ease; 
}

.card-option:hover {
    background-color: rgba( 33, 37, 41, 0.9); /* Cambia el color de fondo al hacer hover */
    color: white;
    border-radius: 5px; /* Añade un pequeño borde redondeado */
    border-color: rgba( 33, 37, 41, 0.9);;
}

.container-creditos{
    padding: 20 20 0 20px;
    background: white;
    min-height: 91vh;
}

.row-saldos-inicio{
    background-color: whitesmoke;
    padding: 8px;
    display: flex;
    justify-items: center;
    align-items: center;
    margin-bottom: 8px;
}

.container-inicio-header{
    padding: 20 20 0 20px;
    background: whitesmoke;
    min-height: 100vh;
}

.container-inicio-body{
    padding: 20 20 20 20px;
    background: white;
}

.col-saldo-inicio{
    border-right: solid 2px rgb(163, 166, 168);
}

.bg-header-modal{
    background-color: #dee2e6;
    color: #6c757d;
}

.content-card-token{
    background: whitesmoke;
    border: solid 1px #dee2e6;
    border-radius: 16px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-card-token p {
    font-size: 50px;
    letter-spacing: 16px;
}

/*COLORES A USAR
$blue:    #0d6efd;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;
*/