.header-card-saldos{
    width: 100%;
    display: flex;
    justify-content: center;

}

.card-saldo{
    margin-bottom: 0px !important;
    margin-left: 10px; 
    margin-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 300px;
}
