
.hstack{
    padding-right: 80px;
}

.offcanvas-end{
    width: 250px !important;
    color: white;
    height: 300px;
}

.dropdown-menu{
    left: -21px !important;
}

.dropdown-menu-perfil{
    left: -10px !important;
}

.dropdown-menu-notif{
    left: -120px !important;
    width: 400px;
    padding: 16px;
}

.div-main-notif{
    width: 400px;
}

.dropdown-menu-notif {
    background-color: white !important; /* Cambia el fondo a blanco */
    color: #000000; /* Cambia el texto a negro */
    border: 1px solid #ccc; /* Opcional: agrega un borde */
}