
.div-card-login{
  border: solid 1px #e9ecef;
  background: white;
  border-radius: 24px 24px 24px 24px;
}

.login-title{
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 900;
}

.spinner-login{
  margin-top: 8px;
  margin-left: 15px;
}

.section-login{
  background: #dee2e6;
}

.div-img-login{
  display: flex;
  justify-content: center;
  align-items: center;
}